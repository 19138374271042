/* You can add global styles to this file, and also import other style files */
@import "src/theme/open_layers_styles.scss";
@import "src/theme/theme.scss";
@import "src/theme/variables.scss";

@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"), url("assets/font/SegoeUI.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Italic"),
    url("assets/font/SegoeUI_Italic.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Bold"),
    url("assets/font/SegoeUI_Bold.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Bold Italic"),
    url("assets/font/SegoeUI_Bold_Italic.woff") format("woff");
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("assets//font/MaterialIcons-Regular.woff2") format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

* {
  box-sizing: border-box;
  font-family: "Segoe UI", "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

.error {
  background-color: $error;
  color: white;
  font-weight: 500;
}

.info {
  background-color: $primary-light;
  color: white;
  font-weight: 500;
}

.success {
  background-color: $light-green;
  color: white;
  font-weight: 500;
}

.warn {
  background-color: $warn;
  color: white;
  font-weight: 500;
}
