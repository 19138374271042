// Variables to access directly from scss

// colors
$primary: #1565c0;
$primary-dark: #0d47a1;
$primary-light: #1976d2;

$light-gray: #f0f0f0;
$gray-ccc: #ccc;
$body-default-color: #4a4a4a;
$icon-default-color: #9b9b9b;
$text-light-gray-color: #9b9b9b;
$dark-gray: #c5c5c5;
$text-muted: #979797;
$error: #d0021b;
$warn: #f5a623;
$accent: #d93f97;
$light-green: #5cb85c;

// media query sizes
$x-large: 1200px;
$large: 1024px;
$medium: 768px;
$small: 480px;
